import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Amap from 'components/utilcomponents/mapAddon/Amap';
import { DataList, PopUp } from 'components';
import { log, isEmptyObj, createTip } from 'utils';
import { DEFAULT_ADDR_MAP_ICON, ERROR } from 'constants';
import { prefixCls } from './index.scss';
import Icon from '../../icon/Icon';
// eslint-disable-next-line import/no-cycle
import MapDialog from './mapDialog';
import { getReturnData } from './utils';

const AddrSugService = Amap.AddrSug;
// 坐标统一用逗号分隔的字符串
const keyboardTip = (
  <div>
    请使用键盘&quot;
    <Icon iconType="icon-arrow-mup" />
    <Icon iconType="icon-arrow-mdown" />
    &quot;键来选择
  </div>
);
class AddrSug extends Component {
  constructor(prop) {
    super(prop);
    let { defaultValue } = prop;
    let selectedPoiData = {};
    let adrvalue = '';
    if (defaultValue === 'string') {
      defaultValue = JSON.parse(prop.defaultValue || '{}');
    }
    if (!defaultValue) defaultValue = {};
    adrvalue = defaultValue.show_val || defaultValue.address || defaultValue.address || '';
    selectedPoiData = prop.valueToJson ? JSON.stringify(defaultValue) : defaultValue;
    this.state = {
      // maplnglat: '', // maplnglat
      adrvalue,
      // mapvalue: '',
      searchStr: '',
      // mapsearchStr: '',
      manualSearch: false,
      selectedPoiData, // 当前选中的完整poi数据
      poiDatas: [], // 当前关键词对应的全部查询结果
      poiDatasRet: [], // 当前关键词对应的全部查询结果的返回集
      // eslint-disable-next-line react/no-unused-state
      mapSearchRes: {}, // 当前关键词对应地图直接结果（不含状态）
    };
    this._currMap = null;
    this._mapPopup = null;
    this._mapShow = null;
  }

  static propTypes = {
    classname: PropTypes.string, // 地址框样式
    tableHeader: PropTypes.array,
    style: PropTypes.object, // 样式
    fieldName: PropTypes.string,
    placeholder: PropTypes.string, // 提示
    mapCenter: PropTypes.array, // 地图中心点
    mapIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // 地图弹框响应图标 不要图标需手动置false
    mode: PropTypes.string, // 查询模式  district , address
    defaultValue: PropTypes.oneOfType([
      // 默认值
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
    ]),
    valiType: PropTypes.string, // 数据校验类型
    valueToJson: PropTypes.bool, // 返回值转为json字符串
    disabled: PropTypes.bool, // 禁用 只读
    onBlur: PropTypes.func, // blur 事件
    onFocus: PropTypes.func,
    onClick: PropTypes.func, // 输入框点击
    onChange: PropTypes.func, // 输入框点击
    onKeyDown: PropTypes.func, // 输入框
    handleSelected: PropTypes.func, // 下拉选择处理
    handleMapSelect: PropTypes.func,
    handleAdrIconClick: PropTypes.func,
    handleMapIconClick: PropTypes.func,
    handleMapConfirm: PropTypes.func,
    dataPath: PropTypes.string, // 光标路径
    handleHideCallback: PropTypes.func, // 地址选择器浮层隐藏的时候触发回掉
    blurCheck: PropTypes.bool, // blur数据检查,
    fullName: PropTypes.bool, // 行政区划显示完整名称,
    customValidity: PropTypes.func,
    required: PropTypes.bool,
    filter: PropTypes.func,
    showTitleTips: PropTypes.bool,
    optionRemovable: PropTypes.func,
    onRemoveOption: PropTypes.func,
  };
  static defaultProps = {
    classname: '',
    mapCenter: [116.397, 39.908],
    mapIcon: DEFAULT_ADDR_MAP_ICON,
    defaultValue: '',
    valueToJson: false,
    blurCheck: false,
    mode: 'address',
    tableHeader: [{ key: 'listVal', colSpan: 2, title: keyboardTip, className: 'keyboard-tip' }, { key: 'info' }],
    /*
     * defaultValue 格式：
     * {
     *  address 地址名
     *  poi  坐标  逗号字符串 对应此处 lnglat
     * }
     */
    /*
     * 返回值：
     *  {
     *   "show_val":"下拉选择后，收入框显示字段"
     *   "province"： "省",
     *   "city": "市",
     *   "district": "区",
     *   "poi": "经度,纬度（字符串）"
     *   "adcode":"行政区划码"
     *  }
     *  网点
     * {
     *  address 地址名
     *  poi  坐标  逗号字符串 对应此处 lnglat
     * }
     */
    // 对外接口方法：
    // getSelectedDetail 获取选中项地址详细数据
    // getAllPois = () => this.state.poiDatas 获取地址查询结果所有poi数据
  };
  handleResetState = () => {
    const reset = {
      adrvalue: '',
      // mapvalue: '',
      searchStr: '',
      // mapsearchStr: '',
      manualSearch: false,
      // maplnglat: '',
      adrSelectDropData: undefined,
    };
    this.setState(...reset);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const tdefaultValue = this.props.defaultValue || {};
    let defaultValue = nextProps.defaultValue || {};
    let selectedPoiData;
    let lnglat = '';
    let adrvalue = '';
    if (defaultValue.show_val !== tdefaultValue.show_val) {
      if (typeof defaultValue === 'string') {
        defaultValue = JSON.parse(defaultValue || '{}');
      }
      lnglat = defaultValue.poi || '';
      adrvalue = defaultValue.show_val || defaultValue.address || defaultValue.address || '';
      selectedPoiData = this.props.valueToJson ? JSON.stringify(defaultValue) : defaultValue;
      // eslint-disable-next-line react/no-unused-state
      this.setState({ adrvalue, selectedPoiData, lnglat });
    }
  }

  getSelectedDetail = () => this.state.selectedPoiData;
  getAllPois = () => this.state.poiDatas;
  mapResultHandle = (status, result) => {
    log(status, ':', result);
    const { mode, fullName, exclude } = this.props;
    const { poiDatasRet = [], poiDatas = [], selectDropData = [] } = getReturnData(status, result, mode, fullName);
    if (exclude) {
      const nodeType = mode === 'district' ? '8' : '7';
      const new_selectNode = exclude.filter(t => +t.node_type === +nodeType);
      const index_selectDropData = selectDropData.map((t, i) => ({ ...t, k_index: i }));
      const new_selectDropData = index_selectDropData.filter((t, i) => {
        const { poi, show_val } = poiDatasRet[i];
        return !new_selectNode.some(r => r.node_address && r.node_address.poi === poi);
      });
      this.setState({
        manualSearch: false,
        poiDatasRet,
        poiDatas,
        // eslint-disable-next-line react/no-unused-state
        mapSearchRes: result,
        adrSelectDropData: new_selectDropData,
      });
      this.input && this.input.loading(false);
      return;
    }
    this.setState({
      manualSearch: false,
      poiDatasRet,
      poiDatas,
      // eslint-disable-next-line react/no-unused-state
      mapSearchRes: result,
      adrSelectDropData: selectDropData,
    });
    this.input && this.input.loading(false);
  };
  showMap = () => {
    // 地图框 map
    const { disabled, valiType, handleMapSelect, mode } = this.props;
    const { selectedPoiData, adrSelectDropData } = this.state;
    let addr = selectedPoiData;
    if (!addr.poi && addr.show_val) {
      addr = {
        ...this.state.poiDatasRet[0],
        show_val: selectedPoiData.show_val,
      };
    }
    const param = {
      onClose: () => (this._mapPopup = null),
      disabled,
      valiType,
      handleMapSelect,
      handleMapConfirm: this.handleMapConfirm,
      mode,
      selectedPoiData: addr,
    };
    this._mapPopup = new PopUp(MapDialog, param).show();
  };
  handleHideCallback = () => {
    this.props.handleHideCallback && this.props.handleHideCallback(this.state.selectedPoiData);
  };
  focusInput = () => {
    this.input && this.input.focus();
  };
  refInput = r => (this.input = r);

  render() {
    // 地址框 adr
    const {
      classname,
      style,
      placeholder,
      mode,
      disabled,
      valiType,
      mapIcon,
      tableHeader,
      dataPath,
      customValidity,
      required,
      filter,
      showTitleTips,
      optionRemovable,
    } = this.props;
    const classes = classnames({ [prefixCls]: prefixCls, [classname]: classname, [`fn-field--${valiType}`]: valiType });
    const imapIcon = mapIcon === true ? DEFAULT_ADDR_MAP_ICON : mapIcon;
    return (
      <DataList
        ref={this.refInput}
        style={style}
        required={required}
        className={classes}
        placeholder={placeholder}
        menuClassName="fn-address__menu"
        disabled={disabled}
        header={tableHeader}
        data={this.state.adrSelectDropData}
        value={this.state.adrvalue}
        data-path={dataPath}
        customValidity={customValidity}
        highlight="listVal"
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onFocus={this.props.onFocus}
        filter={this.filter}
        onChange={this.onChange}
        onSelect={this.handleSelected}
        onClose={this.handleHideCallback}
        showTitleTips={showTitleTips}
        optionRemovable={optionRemovable}
        onRemoveOption={this.onRemoveOption}
      >
        <AddrSugService
          fetch={filter}
          onResult={this.mapResultHandle}
          searchStr={this.state.searchStr}
          mode={mode}
          resultNum={mode === 'district' ? 20 : 10}
          manualSearch={this.state.manualSearch}
        />
        {imapIcon && <i className={`input-icon fn-icon fn-${imapIcon}`} onClick={this.handleAdrIconClick} />}
      </DataList>
    );
  }

  filter = searchStr => {
    if (this.preSearchStr === searchStr) return;
    if (searchStr && searchStr.trim()) {
      this.input && this.input.loading(true);
    }
    this.preSearchStr = searchStr;
    this.setState({ manualSearch: true, searchStr });
  };
  onBlur = e => {
    const { selectedPoiData } = this.state;
    if (this.props.blurCheck && selectedPoiData && selectedPoiData.show_val && !selectedPoiData.province) {
      createTip('请从下拉中选择数据', ERROR, undefined, undefined, undefined, true).show();
    }
    this.props.onBlur && this.props.onBlur(selectedPoiData, this.props.fieldName, e);
  };
  onKeyDown = e => {
    if (e.keyCode === 13 && !e.target.dataset.isSelect) {
      const { selectedPoiData } = this.state;
      this.props.onKeyDown && this.props.onKeyDown(selectedPoiData, this.props.fieldName);
    }
  };
  onChange = searchStr => {
    const poiDatasRet = {
      province: '',
      city: '',
      district: '',
      adcode: '',
      poi: '',
      street: searchStr,
      show_val: searchStr,
    };
    this.setState({ searchStr, adrvalue: searchStr, selectedPoiData: poiDatasRet });
    this.props.onChange && this.props.onChange(poiDatasRet);
  };

  onRemoveOption = (option, i) => {
    const adrSelectDropData = [...this.state.adrSelectDropData];
    adrSelectDropData.splice(i, 1);
    this.setState({ adrSelectDropData });
    this.props.onRemoveOption && this.props.onRemoveOption(option, i);
  };
  // 下拉选中 adr map
  handleSelected = item => {
    if (isEmptyObj(item)) {
      this.setState({ selectedPoiData: {}, adrvalue: '' });
      this.props.handleSelected && this.props.handleSelected({});
      return;
    }
    const { valueToJson } = this.props;
    const selectedIndex = item.k_index || item.index;
    if ((selectedIndex === '-1' || selectedIndex === '') && item.value === '') return false;
    let selectedPoiData = this.state.poiDatasRet[selectedIndex] || {
      show_val: item.value,
      province: '',
      city: '',
      district: '',
      adcode: '',
      poi: '',
    };
    const adrvalue = item.value;
    this.preSearchStr = adrvalue;
    this.setState({ selectedPoiData, adrvalue });
    selectedPoiData = valueToJson ? JSON.stringify(selectedPoiData) : selectedPoiData;
    this.props.handleSelected && this.props.handleSelected(selectedPoiData);
  };
  // 图标点击  adr map
  handleAdrIconClick = () => {
    this.props.handleAdrIconClick && this.props.handleAdrIconClick(this.state.selectedPoiData);
    this.showMap();
  };
  // 地图内 确定按钮
  handleMapConfirm = iselectedPoiData => {
    let selectedPoiData = iselectedPoiData;
    if (isEmptyObj(selectedPoiData)) return;
    const { valueToJson } = this.props;
    const lnglat = selectedPoiData.poi;
    const adrvalue = selectedPoiData.show_val;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ selectedPoiData, adrvalue, lnglat });
    selectedPoiData = valueToJson ? JSON.stringify(selectedPoiData) : selectedPoiData;
    this.props.handleSelected && this.props.handleSelected(selectedPoiData);
    this.props.handleMapConfirm && this.props.handleMapConfirm(selectedPoiData);
  };
}
export default AddrSug;
