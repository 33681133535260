/**
 * 在UploadImg基础上做的一点点修改。功能基本上一样，主要是样式不同
 */
// 引入源
import { sentryFormUploadReporter } from '@/utils/utils';
import { ButtonIcon, Icon } from 'components';
import { ERROR } from 'constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compressImg, genImageURL, showInfo, viewImage } from 'utils';
import './index.scss';

export default class UploadImage extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    photoProps: PropTypes.object,
    url_path: PropTypes.string,
    fileType: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    maxNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    maxSize: PropTypes.number,
  };
  static defaultProps = {
    name: 'photoImgs',
    maxSize: 5,
    // photoProps: this.props.photoProps
  };

  constructor(props) {
    super(props);
    const currPhotoProps = this.props.photoProps || {};
    const photoNum = 'photoProps' in this.props ? Object.keys(currPhotoProps) : [];
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      style: {
        opacity: 1,
        display: 'block',
        num: 1,
      },
      // eslint-disable-next-line react/no-unused-state
      maxNum: Number(this.props.maxNum) || 1,
      photoNum, // 存放图片索引
      photoImg: currPhotoProps || {}, // 存放图片详情
      photoProps: {}, // 存放新的图片对象
      picName: currPhotoProps.newName || '', // 照片名字
    };
  }

  // 证件切换图片内容清空
  UNSAFE_componentWillReceiveProps(nextProp) {
    if (nextProp.photoProps !== this.props.photoProps && !nextProp.photoProps) {
      this.clearInfo();
    }
  }

  clearInfo = () => {
    this.setState({
      photoNum: [], // 存放图片索引
      photoImg: {}, // 存放图片详情
      photoProps: {},
      picName: '',
    });
    this.myTextInput.value = '';
  };

  handleChange = () => {
    const _this = this;
    const { maxSize } = this.props;
    const fileObj = _this.myTextInput.files[0];
    const oriName = fileObj.name;
    const regExp = /\.jpg|\.JPG|\.gif|\.GIF|\.png|\.PNG|\.jpeg|\.JPEG]/;
    if (!regExp.test(oriName)) {
      this.clearInfo();
      return showInfo(ERROR, '请上传以下格式图片：jpg、gif、jpeg、png！');
    }
    compressImg(_this.myTextInput.files[0], { width: 800, quality: 0.7, height: 800 }).then(rst => {
      const _timestamp = Date.parse(new Date());
      const _name = _timestamp;
      const _img = {
        src: rst.base64,
        formdata: rst.file,
        name: _name,
      };
      const photoProps = { ...this.state.photoProps, [_name]: _img };
      const sizeSum = Object.values(photoProps).reduce((a, b) => a + (b.formdata ? b.formdata.size : 0), 0);
      if (sizeSum > maxSize * 1024 * 1024) {
        _this.myTextInput.value = '';
        return showInfo(ERROR, `图片总大小压缩后不能大于${maxSize}M`);
      }
      _this.setState({
        photoNum: [...this.state.photoNum, _name],
        photoImg: { ...this.state.photoImg, [_name]: _img },
        photoProps,
        picName: rst.origin.name,
      });
      this.props.onChange && this.props.onChange(_name);
      return rst;
    });
  };
  handleRemove = event => {
    const _this = this;
    const _name = event.target.getAttribute('data-name');
    const _obj = Object.assign({}, _this.state.photoImg);
    const _objProps = Object.assign({}, _this.state.photoProps);
    delete _obj[_name];
    if (_objProps[_name]) {
      delete _objProps[_name];
    }
    _this.setState({
      photoImg: _obj,
      photoNum: this.state.photoNum.filter(item => `${item}` !== _name && `${item}` !== 'newName'),
      photoProps: _objProps,
      picName: '',
    });
    this.myTextInput.value = '';
    this.props.onChange && this.props.onChange(_name);
  };
  handleClick = () => {
    this.myTextInput.click();
    sentryFormUploadReporter({
      uploadInfo: {
        uploadComponent: 'UploadImage',
        props: this.props,
      },
    });
  };
  handleView = src => {
    viewImage({ src });
  };

  render() {
    // let picState = Object.keys(this.state.photoProps)
    // let picNum = this.state.photoNum
    // let picProp = Object.keys(this.props.photoProps)
    const pictureName = this.state.photoNum.length > 0 && (this.state.picName || this.state.photoImg.newName);
    let buttonHide = '';
    if (pictureName && pictureName !== '') {
      buttonHide = 'hide';
    }
    const type = this.props.fileType || 'truck';
    const _html = (
      <ButtonIcon
        disabled={this.props.disabled || false}
        iconType="icon-upload"
        classname={buttonHide}
        type="primary"
        onClick={this.handleClick}
      >
        上传照片
      </ButtonIcon>
    );
    return (
      <div className="image-wrap" data-id={this.props.name}>
        {_html}
        <div className="img-btn-wrap clearfix" ref={r => (this.imgWrap = r)}>
          {this.state.photoNum.newName !== '' &&
            this.state.photoNum
              .filter(item => item !== 'newName' && item !== '')
              .reduce((prev, item) => {
                const _photoImg = this.state.photoImg[item] || {};
                let _viewSrc = '';
                let showPicName = null;
                if (_photoImg.src) {
                  showPicName = (
                    <p className="click-show" onClick={() => this.handleView(_viewSrc)}>
                      {this.state.picName || pictureName}
                    </p>
                  );
                  _viewSrc = _photoImg.src;
                } else {
                  const photoSrc = genImageURL({ type, ..._photoImg });
                  showPicName = (
                    <p className="click-show" onClick={() => this.handleView(photoSrc)}>
                      {this.state.picName || pictureName}
                    </p>
                  );
                  _viewSrc = photoSrc;
                }
                let _delButton = (
                  <i
                    className="fn-icon fn-icon-error-o"
                    ref={r => (this.imgDelete = r)}
                    data-name={_photoImg.name}
                    onClick={this.handleRemove}
                  />
                );
                if (this.props.disabled) {
                  _delButton = '';
                }
                prev.push(
                  <button className="out-box" disabled={this.props.disabled ? 'disabled' : 'false'}>
                    <Icon iconType="icon-clip" />
                    {showPicName}
                    {_delButton}
                  </button>,
                );
                return prev;
              }, [])}
        </div>
        <input
          type="file"
          style={{ display: 'none' }}
          data-name="photo"
          ref={r => (this.myTextInput = r)}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
