import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { OrderedMap } from 'immutable';
import { Generator, Tips } from 'components';
import {
  fromJS,
  setComVal,
  getComVal,
  getCardFormData,
  getReturnValue,
  typeIs,
  formatCamelCase,
  isEmptyObj,
  emptyFunction,
  formDataToCardForm,
  isset,
  setCardFormData as setCardFormDataUtil,
} from 'utils';
import * as validate from 'utils/validate';
import { dataStrippingConfig } from 'utils/dataStripping/dataStrippingConfig';
import { EMPTY_VAL_MAP, validateTips, regulars } from 'constants';
import { prefixCls } from './index.scss';

const CARD_FORM_BTN_TYPES = ['Button', 'ButtonIcon', 'Label'];
export default class CardForm extends PureComponent {
  static defaultProps = {
    classname: '',
    isHeader: true,
    customerBody: false,
    useDefaultCls: true,
    showBody: true,
    showHeaderTips: false,
    cookieName: window.location.pathname,
    storeShowBody: false,
    isCanHeaderClick: true,
  };
  static propTypes = {
    bodyClass: PropTypes.string,
    classname: PropTypes.string,
    title: PropTypes.object,
    data: PropTypes.object,
    header: PropTypes.object,
    headerClass: PropTypes.string,
    isHeader: PropTypes.bool,
    handleEvents: PropTypes.object, // 所有的事件入口
    handleHeaderEvents: PropTypes.object, // header所有的事件入口
    reactKey: PropTypes.string,
    getPaths: PropTypes.func, // 追踪路径
    bodyHideCallBack: PropTypes.func, // cardForm body 关闭后执行的回调
    customerBody: PropTypes.bool, // 是否为自定义body
    bodyItem: PropTypes.any, // 自定义情况下，外部传入的bodyItem
    defaultFocusKey: PropTypes.string, // 自动聚焦的key值 从js调用时指定，TODO 未完成
    // 模板中可对每个字段传入是否聚焦：defaultFocus, 多个字段有defaultFocus，则取第一个
    // 优先级 模板第一个defaultFocus > js中props 传入CardForm的 defaultFocusKey
    useDefaultCls: PropTypes.bool, // 是否保留 默认样式
    showBody: PropTypes.bool,
    headerTips: PropTypes.string,
    showHeaderTips: PropTypes.bool,
    storeShowBody: PropTypes.bool, // 本地是否存储showBody状态
    propDataInitOnly: PropTypes.bool, // props.data 只用于初始化
    cookieName: PropTypes.string, // 配合storeShowBody存储showBody状态，不传默认为window.location.pathname
    customValidate: PropTypes.any,
    style: PropTypes.object,
    extraContent: PropTypes.node,
    isCanHeaderClick: PropTypes.bool, // 表头是否可以点击
  };

  constructor(prop) {
    super(prop);
    this.state = {
      data: fromJS(prop.data) || OrderedMap(),
      header: '',
      // eslint-disable-next-line no-nested-ternary
      showBody: prop.storeShowBody
        ? localStorage[prop.cookieName] !== undefined
          ? !!localStorage[prop.cookieName]
          : prop.showBody
        : prop.showBody,
    };
    this.form = {};
    this.virtualDom = {};
    this.events = {
      onChange: this.handleEvents,
      handleSelected: this.handleEvents,
      onSelect: this.handleEvents,
      treeSelected: this.handleEvents,
      onBlur: this.handleEvents,
      onFocus: this.handleEvents,
      onClick: this.handleEvents,
      handleClick: this.handleEvents,
      handleAddTodo: this.handleEvents,
      handleIconClick: this.handleEvents,
      iconClick: this.handleEvents,
      onKeyDown: this.handleOnKeyDown,
      handleHideCallback: this.handleHideCallback,
      handleEvents: this.handleEvents, // ccombiner的回调方法
      handleOperateClick: this.handleEvents, //
      onSuffixClick: this.onSuffixClick,
    };
    this.headerEvents = {
      onChange: this.handleHeaderEvents,
      handleSelected: this.handleHeaderEvents,
      onSelect: this.handleHeaderEvents,
      onBlur: this.handleHeaderEvents,
      onFocus: this.handleHeaderEvents,
      onClick: this.handleHeaderEvents,
      handleIconClick: this.handleHeaderEvents,
      handleHideCallback: this.handleHideCallback,
    };
  }

  // body 区域显示、隐藏, 由外部值时， 取外部状态
  toggleBody = (showBodyType, emitCallback = true) => {
    // this.setState({ showBody: !this.state.showBody })
    // 此处是为了防止 收起时 整个cardForm的宽度变化
    if (showBodyType === this.state.showBody) return;
    // let width = this.body.clientWidth
    // if (width === 0) width = this.header.clientWidth
    const state = { showBody: showBodyType === undefined ? !this.state.showBody : showBodyType };
    // if (width !== 0) state = Object.assign(state, { headerWidth: width - 14 })
    this.setState(state);
    if (this.props.storeShowBody) {
      localStorage[this.props.cookieName] = state.showBody ? '1' : '';
    }
    // 隐藏或者显示 body 后执行的回调
    if (emitCallback && typeof this.props.bodyHideCallBack === 'function') {
      const para = this.state.showBody ? 1 : -1;
      if (this.state.showBody) this.clientHeight = this.body.clientHeight || 0;
      const height = (this.clientHeight || 0) * para;
      this.props.bodyHideCallBack(height, state.showBody);
    }
  };
  // 获取cardForm 中的header数据
  getStateHeader = () => this.state.headerItem.toJS();
  // 获取cardForm 配置数据
  getStateData = () => this.state.data.toJS();
  // 获取cardForm 最终组件值提交数据
  getStateDataForm = () => getCardFormData(this.state.data.toJS());
  // 设置cardForm的值
  setCardFormData = (newData, formData) => {
    const newFormData = fromJS(setCardFormDataUtil(newData, formData));
    this.setState({
      data: newFormData,
    });
  };
  // 将最终组件值提交数据 写入cardForm 参数： formKeyVals , saveAs
  setStateDataForm = (formData, saveAs = 'data') => {
    if (isEmptyObj(formData)) return;
    this.setState({ [saveAs]: formDataToCardForm(this.state[saveAs], formData, true) });
  };
  // TODO 将被移除 目前有bug; 内部将不在单独维护form值，即this.form 也将去除
  getStateForm = () => this.form;
  getState = () => this.state;
  setData = data => {
    this.setState({ data: fromJS(data) });
  };
  setHeaderData = data => {
    this.setState({ headerItem: fromJS(data) });
  };
  showBody = showBody => {
    this.setState({ showBody });
  };
  changeState = (newState, callback) => {
    this.setState({ ...newState }, callback);
  };
  /**
   * 外部设置组件props
   * @param  {[type]} key        [组件字段名]
   * @param  {[type]} value      [设置值]
   * @param  {[type]} type       [组件类型]
   * @param  {[type]} saveAs     [数据块名] headerItem data
   * @return {[type]} null     [description]
   */
  setComProps = (key, propsKey, props, type, saveAs = 'data', idata) => {
    let data = idata;
    if (!data) {
      data = this.state[saveAs];
    } else if (!data.toJS) {
      data = fromJS(data);
    }
    const propsKeyPath = [key, 'otherProps', propsKey];
    const _data = data.setIn(propsKeyPath, props);
    // this.form = { ...this.form, [key]: {} } // 清空form数据
    this.setState({ [saveAs]: _data });
  };
  /**
   * 外部添加组件的验证信息
   */
  setComValidate = (key, validateType, saveAs = 'data', flag) => {
    const data = this.state[saveAs].toJS();
    let _data = fromJS(data);
    const labelPath = [key, 'labelProps', 'isRequired'];
    let validateList = data[key].validate || [];
    if (validateList.indexOf(validateType) === -1) {
      validateList.push(validateType);
      if (validateType === 'require') {
        _data = _data.setIn(labelPath, flag);
      }
    }
    if (!flag) {
      validateList.indexOf(validateType) > -1 && (validateList = validateList.filter(elem => elem !== validateType));
      _data = _data.setIn(labelPath, false);
    }
    const propsKeyPath = [key, 'validate'];
    _data = _data.setIn(propsKeyPath, validateList);
    this.setState({ [saveAs]: _data });
  };
  /**
   * @param dataList 【需要设置的key, value, type】
   * @param  {[type]} saveAs     [数据块名] headerItem data
   * @return {[type]} null     [description]
   */
  setComValBatch = (dataList, saveAs = 'data', data) => {
    let _data = data || this.state[saveAs];
    dataList.forEach(item => {
      _data = this.setComValAndGoBackData(item.key, item.value, item.type, saveAs, _data);
    });
    this.setState({ [saveAs]: _data });
  };
  /**
   * @param dataList 【需要设置的key, propKey, value, type】
   * @param  {[type]} saveAs     [数据块名] headerItem data
   * @return {[type]} null     [description]
   */
  setComPropBatch = (dataList, saveAs = 'data', idata) => {
    let data = idata;
    if (!data) {
      data = this.state[saveAs];
    } else if (!data.toJS) {
      data = fromJS(data);
    }
    dataList.forEach(item => {
      data = data.setIn([item.key, 'otherProps', item.propKey], item.value);
    });
    this.setState({ [saveAs]: data });
  };
  /**
   * 设置一级属性（validate、classname等）
   * @param dataList 【需要设置的key, propKey, value, type】
   * @param  {[type]} saveAs     [数据块名] headerItem data
   * @return {[type]} null     [description]
   */
  setComProp = (dataList, saveAs = 'data', idata) => {
    let data = idata;
    if (!data) {
      data = this.state[saveAs];
    } else if (!data.toJS) {
      data = fromJS(data);
    }
    dataList.forEach(item => {
      data = data.setIn([item.key, item.propKey], item.value);
    });
    this.setState({ [saveAs]: data });
  };
  /**
   * @param  {[type]} key        [组件字段名]
   * @param  {[type]} value      [设置值]
   * @param  {[type]} type       [组件类型]
   * @param  {[type]} saveAs     [数据块名] headerItem data
   * @param  {[type]} callback   [state更新后的回调]
   * @return {[type]} null     [description]
   */
  setComVal = (key, value, type = 'Input', saveAs = 'data', data, callback) => {
    const _data = this.setComValAndGoBackData(key, value, type, saveAs, data);
    this.setState({ [saveAs]: _data }, () => {
      callback && callback();
    });
  };
  /**
   * @param  {[type]} key        [组件字段名]
   * @param  {[type]} value      [组件值]
   */
  getComVal = key => {
    const allData = this.state.data.toJS();
    if (!key || !allData[key]) return false;
    return getComVal(allData[key]);
  };
  /**
   * 从外边更新数据
   * @param  {[type]} key    更新的key名称
   * @param  {[type]} value  值
   * @param  {String} type   类型Input等
   * @param  {[type]} saveAs state的key
   * @param  {[type]} data   如果传了data就使用data来做更新, 如果没传使用state中的saveAs做更新
   * @return {[type]}        [description]
   */
  setComValAndGoBackData = (key, value, type, saveAs, idata) => {
    let data = idata;
    if (!data) {
      data = this.state[saveAs];
    } else if (!data.toJS) {
      data = fromJS(data);
    }
    let eventLevel = data.getIn([key, 'eventLevel']) || 'error'; // 级别
    const validates = data.getIn([key, 'validate']) || []; // 验证规则
    const validateKey = data.getIn([key, 'validateKey']) || null; // 对象型数据 校验字段
    const labelName = (data.getIn([key, 'labelProps']) || {}).children || ''; // label值
    const _value = value;
    const res = this.checkValidate(_value, validates, eventLevel, validateKey, labelName);
    eventLevel = res.styleType;
    const valiTypePath = [key, 'otherProps', 'valiType'];
    const _data = data.set(key, setComVal(data.get(key), _value)).setIn(valiTypePath, eventLevel);
    this.form = { ...this.form, [key]: _value };
    return _data;
  };
  // headerClick
  headerClick = event => {
    // 点击其他的可编辑区域时，不触发cardForm的click
    if ((event.target !== this.header && !event.target.disabled) || !this.props?.isCanHeaderClick) return;
    // if (this.header === event.target) {
    this.toggleBody();
    // }
  };
  handleHeaderEvents = (key, event, ...rest) => {
    if (key === 'cardFormToggler' && event.type === 'click') {
      this.toggleBody();
    }
    const type = rest[rest.length - 2];
    const eventName = rest[rest.length - 1] || '';
    this.dealData(key, event, type, eventName, 'headerItem');
  };
  handleHideCallback = (key, selectValues, fieldName, that) => {
    const { handleEvents = {} } = this.props;
    if (handleEvents.handleHideCallback) {
      handleEvents.handleHideCallback(selectValues, fieldName, key, that);
    }
  };
  onSuffixClick = key => {
    const { handleEvents = {} } = this.props;
    if (handleEvents.onSuffixClick) {
      handleEvents.onSuffixClick(key);
    }
  };
  handleEvents = (key, event, ...rest) => {
    const type = rest[rest.length - 2];
    let eventName = rest[rest.length - 1] || '';
    if (type === 'Ccombiner') {
      eventName = rest[rest.length - 4];
      console.log('here is Ccombiner event', rest[rest.length - 4]);
    }
    this.dealData(key, event, type, eventName, 'data', { oriParams: [key, event, ...rest] });
  };
  /*
   *  handleEvents 参数:
   * @param  {[type]} key          [组件字段名]
   * @param  {[type]} _value       [组件value]
   * @param  {[type]} this.form    [cardForm form data]
   * @param  {[type]} _data.toJS() [cardForm 配置json]
   * @param  {[type]} saveAs       [数据块名]
   * @param  {[type]} type         [组件类型]
   * @param  {[type]} event        [组件 event]
   * @param  {[type]} extra        [额外信息，一般是组件 handler 的原始参数列表]
   * )
   */
  dealData = (key, event, type, eventName, saveAs, extra) => {
    const { handleEvents = {}, handleHeaderEvents = {} } = this.props;
    let eventHandler = emptyFunction;
    if (saveAs === 'data' && typeIs(handleEvents[eventName], 'function')) {
      eventHandler = handleEvents[eventName];
    } else if (saveAs === 'headerItem' && typeIs(handleHeaderEvents[eventName], 'function')) {
      eventHandler = handleHeaderEvents[eventName];
    }
    if (
      CARD_FORM_BTN_TYPES.includes(type) ||
      eventName === 'iconClick' ||
      (type === 'ButtonDrop' && eventName === 'onClick')
    ) {
      // 此处回传的event根据调用的组件的不同，对应意义不同，对于input, event为 handle Event， 对于selectDrop, event为value， 慎用
      eventHandler(key, '', this.form, this.state[saveAs].toJS(), saveAs, type, event, extra);
      return;
    }
    // Table 组件的有些事件不需要改变数据，直接透出
    if (type === 'Table' && ['handleOperateClick'].includes(eventName)) {
      eventHandler(key, '', this.form, this.state[saveAs].toJS(), saveAs, type, event, extra);
      return;
    }
    // TODO: 通过CardForm渲染的 Table 中的控件输入，还没进行支持
    const data = this.state[saveAs]; // 数据
    let eventLevel = data.getIn([key, 'eventLevel']) || 'error'; // 级别
    const validates = data.getIn([key, 'validate']) || []; // 验证规则
    const validateKey = data.getIn([key, 'validateKey']) || null; // 对象型数据 校验字段
    const labelName = (data.getIn([key, 'labelProps']) || {}).children || ''; // label值
    const _value = getReturnValue(event, type); // 拿到真真的value
    const res = this.checkValidate(_value, validates, eventLevel, validateKey, labelName);
    eventLevel = res.styleType;
    const valiTypePath = [key, 'otherProps', 'valiType'];
    // 有的组件事件不需要setComVal
    let _data = {};
    if (type === 'SelectDrop' && (eventName === 'handleClick' || eventName === 'onFocus')) {
      _data = data.setIn(valiTypePath, eventLevel);
    } else {
      _data = data.set(key, setComVal(data.get(key), _value)).setIn(valiTypePath, eventLevel);
    }
    this.preData = this.state[saveAs];
    this.form = { ...this.form, [key]: _value };
    this.setState({ [saveAs]: _data });
    /*
     setTimeout(() => eventHandler(key, _value, this.form, _data.toJS(), saveAs, type, event), 0)
     */
    // 此处回传的event根据调用的组件的不同，对应意义不同，对于input, event为 handle Event， 对于selectDrop, event为value, 慎用
    eventHandler(key, _value, this.form, _data.toJS(), saveAs, type, event, extra);
  };
  handleOnKeyDown = (key, value) => {
    const { handleEvents = {} } = this.props;
    const { onKeyDown } = handleEvents;
    if (typeIs(onKeyDown, 'function')) {
      onKeyDown(key, value, this.form, this.state.data.toJS());
    }
  };
  checkValidate = (
    value,
    validates = [],
    eventLevel,
    validateKey,
    labelName = '',
    customValidate = this.props.customValidate,
  ) => {
    let isPass = true;
    let msg = '';
    let otherInfo = {};
    let styleType = eventLevel || 'error';
    for (const validateName of validates) {
      const _validateName = formatCamelCase(`handle_${validateName}`);
      if (typeIs(validate[_validateName], 'function') && isPass) {
        const res = validate[_validateName](value, validateKey, labelName);
        isPass = res.isPass;
        msg = res.msg;
        otherInfo = res;
      } else if (regulars[validateName] && value !== '') {
        const rule = new RegExp(regulars[validateName]);
        isPass = rule.test(value);
        if (!isPass) {
          msg = validateTips[regulars[validateName]];
        }
        otherInfo = {
          isPass,
          msg,
        };
      }
      if (customValidate && typeIs(customValidate[validateName], 'function') && isPass) {
        const res = customValidate[validateName](value, validateKey);
        isPass = res.isPass;
        msg = res.msg;
      }
      if (!isPass) {
        break;
      }
    }
    // if (isPass && eventLevel !== 'default') { // 通过验证将eventLevel 改为default
    if (isPass) {
      // 通过验证将eventLevel 改为default
      styleType = 'default';
    }
    return { styleType, msg, otherInfo };
  };
  // 检查一个字段是否满足要求，blur或者change时修改
  validateCheckItem = (key, value, saveAs = 'data') => {
    let itemData = this.state[saveAs].toJS()[key];
    const eventLevel = itemData.eventLevel || 'error';
    const validates = itemData.validate || [];
    const labelName = (itemData.labelProps || {}).children || '';
    const validateKey = itemData.validateKey || null;
    itemData = setComVal(itemData, value).toJS();
    const { styleType, msg, otherInfo } = this.checkValidate(value, validates, eventLevel, validateKey, labelName);
    const typePath = ['otherProps', 'valiType'];
    if (styleType !== 'default') {
      return { isPass: false, msg, itemData: fromJS(itemData).setIn(typePath, styleType).toJS(), otherInfo };
    }
    return { isPass: true };
  };
  saveCheck = (data, saveAs) => {
    let isPass = true;
    let error = {};
    let errorInfo;
    const checkData = data;
    for (const key of Object.keys(checkData)) {
      const eventLevel = checkData[key].eventLevel || 'error';
      const validates = checkData[key].validate || [];
      const validateKey = checkData[key].validateKey || null;
      const value = getComVal(checkData[key]);
      const labelName = (checkData[key].labelProps || {}).children || '';
      const { styleType, msg } = this.checkValidate(value, validates, eventLevel, validateKey, labelName);
      const typePath = ['otherProps', 'valiType'];
      if (styleType !== 'default') {
        const _value = fromJS(checkData[key]).setIn(typePath, styleType);
        checkData[key] = _value.toJS();
        isPass = false;
        error = checkData[key];
        error.key = key;
        errorInfo = msg;
      }
    }
    if (!isPass) {
      this.setState({ [saveAs]: fromJS(checkData) });
    }
    // return { isPass, error }
    return { isPass, error, errorInfo };
  };
  validatePass = () => {
    const { isPass, error, errorInfo } = this.saveCheck(this.state.data.toJS(), 'data');
    if (!isPass || this.state.headerItem === undefined) return { isPass, error, errorInfo };
    return this.saveCheck(this.state.headerItem.toJS(), 'headerItem');
  };

  UNSAFE_componentWillMount() {
    const headTitle = (this.props.title && this.props.title.item) || this.props.header;
    if (headTitle) {
      this.setState({
        headerItem: fromJS(headTitle),
      });
    }
  }

  /**
   * 初始化CardForm中所有的组件状态
   * @return {[type]} [description]
   */
  handleResetState = () => {
    this.setState({ from: {} });
    if (Object.keys(this.virtualDom).length) {
      Object.entries(this.virtualDom).forEach(
        ([, virtualDom]) => virtualDom && virtualDom.handleResetState && virtualDom.handleResetState(),
      );
    }
  };
  // 如果是外部data值变化
  // nextProps更新的header会覆盖cardFrom中State中维护的headerItem值
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data && !nextProps.propDataInitOnly) {
      this.setState({
        data: fromJS(nextProps.data),
      });
    }
    // 此处接收外部header出问题， 先注销掉。
    const headTitle = (nextProps.title && nextProps.title.item) || nextProps.header;
    const thisTitle = (this.props.title && this.props.title.item) || this.props.header;
    if (headTitle && headTitle !== thisTitle) {
      this.setState({
        headerItem: fromJS(headTitle),
      });
    }
  }

  /**
   * [getVirtualDom 获取虚拟DOM]
   * @param  {[type]} key        [字段的名称]
   * @param  {[type]} type       [类型]
   * @param  {[type]} virtualDom [虚拟DOM]
   * @return {[type]}            [description]
   */
  getVirtualDom = (key, type, virtualDom) => {
    this.virtualDom[key] = virtualDom;
  };
  setFocus = key => {
    this.virtualDom[key] && this.virtualDom[key].focus && this.virtualDom[key].focus();
  };
  renderEveryCardItem = ([key, value]) => {
    // bug 49850
    if (value.display !== undefined && !value.display) return false;

    const { useDefaultCls, getPaths } = this.props;
    const getCardItemCls = cls =>
      classnames({
        [`${prefixCls}__card-item`]: useDefaultCls,
        [cls]: cls,
      });
    // 传入值有对应的 EMPTY_VAL_MAP 空值映射， 则按空值显示 -> 暂为运单列表筛选使用
    const mapEmptyValue = v => {
      const { type } = v;
      if (type) {
        const path = dataStrippingConfig[v] || dataStrippingConfig.default;
        const itemValue = isset(v, path.join('.'));
        const emptyValue = EMPTY_VAL_MAP[itemValue];
        return emptyValue !== undefined
          ? fromJS(v)
              .setIn([...path], '')
              .toJS()
          : v;
      }
      return v;
    };
    return (
      <Generator
        classname={getCardItemCls(value.classname)}
        key={key}
        name={key}
        props={mapEmptyValue(value)}
        tips={value.tips}
        getPaths={(...rest) => [...getPaths(key), ...rest]}
        getVirtualDom={this.getVirtualDom}
        events={this.events}
        style={value.style}
      />
    );
  };
  renderEveryCard() {
    let { data } = this.state;
    if (isEmptyObj(data)) return; // 数据为空 undefined 返回
    data.toJS && (data = data.toJS()); // 防止immutable
    const newData = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value.group) {
        newData[value.group] = newData[value.group] ? newData[value.group] : {};
        newData[value.group][key] = value;
      } else if (value.group_in_default) {
        // 聚合在default 中，防止在页面中换行[pms:42557]
        newData.default = newData.default ? newData.default : {};
        newData.default[value.group_in_default] = newData.default[value.group_in_default] || {};
        newData.default[value.group_in_default][key] = value;
        newData.default[value.group_in_default].group_in_default = value.group_in_default;
      } else {
        newData.default = newData.default ? newData.default : {};
        newData.default[key] = value;
      }
    });
    return Object.entries(newData).map(([gk, gv]) => (
      <div key={gk} className={`cardform_group_${gk}`}>
        {Object.entries(gv).map(([key, val]) => {
          const value = val;
          if (value.group_in_default) {
            const groupKey = value.group_in_default;
            delete value.group_in_default;
            return (
              <div className={`group_in_default group_in_default-${groupKey}`}>
                {Object.entries(value).map(this.renderEveryCardItem)}
              </div>
            );
          }
          return this.renderEveryCardItem([key, value]);
        })}
      </div>
    ));
    // return Object.entries(data).map(([key, value]) =>
    //   (<Generator
    //     classname={getCardItemCls(value.classname)}
    //     key={key}
    //     name={key}
    //     props={mapEmptyValue(value)}
    //     tips={value.tips}
    //     placement={value.placement}
    //     getPaths={(...rest) => [...getPaths(key), ...rest]}
    //     getVirtualDom={this.getVirtualDom}
    //     events={this.events}
    //     style={value.style}
    //   />)
    // )
  }

  renderHeader(headerClass) {
    const header = this.state.headerItem.toJS();
    const headerContent = header
      ? Object.entries(header).map(([key, value]) => {
          if (key === 'cardFormToggler') {
            return (
              <Generator
                classname={classnames(`${prefixCls}__header_item`, value.classname, {
                  'fn-icon-transform': !this.state.showBody,
                })}
                key={key}
                name={key}
                props={value}
                tips={value.tips}
                getVirtualDom={this.getVirtualDom}
                events={this.headerEvents}
              />
            );
          }
          return (
            <Generator
              classname={classnames(`${prefixCls}__header_item`, value.classname)}
              key={key}
              name={key}
              props={value}
              tips={value.tips}
              getVirtualDom={this.getVirtualDom}
              events={this.headerEvents}
            />
          );
        })
      : null;
    // 整个header点击都可以触发toggleBody事件
    const headerCls = classnames({
      [`${prefixCls}__header`]: true,
      [headerClass]: true,
      [`${prefixCls}__header_unborder`]: !this.state.showBody,
    });
    return (
      <div className={headerCls} onClick={this.headerClick}>
        <header
          // style={{ width: this.state.headerWidth }}
          ref={r => (this.header = r)}
        >
          {headerContent}
        </header>
      </div>
    );
  }

  // 带tips的header
  renderTipsHeader(headerClass) {
    const headerTips = this.props.headerTips || '单击鼠标行可展开/收起';
    return (
      <Tips className="detail-tips" type="warn" title={headerTips}>
        {this.renderHeader(headerClass)}
      </Tips>
    );
  }

  render() {
    const { classname, bodyClass, headerClass, isHeader, useDefaultCls, style } = this.props;
    const classes = classnames({
      [prefixCls]: useDefaultCls,
      [classname]: classname,
    });
    const bodyclasses = classnames({
      [`${prefixCls}__hide`]: !this.state.showBody,
      [`${prefixCls}__content`]: useDefaultCls,
      [bodyClass]: bodyClass,
    });
    return (
      <div style={style} className={classes}>
        {isHeader && this.props.showHeaderTips && this.renderTipsHeader(headerClass)}
        {isHeader && !this.props.showHeaderTips && this.renderHeader(headerClass)}
        <section className={bodyclasses} ref={r => (this.body = r)}>
          {!this.props.customerBody && !isEmptyObj(this.props.data) && this.renderEveryCard()}
          {this.props.customerBody && this.props.bodyItem}
          {this.props.extraContent && this.props.extraContent}
        </section>
      </div>
    );
  }
}
