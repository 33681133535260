import { genImageURL, showInfo, getFileUrl, getViewFileType } from 'utils';
import _ from 'lodash';
import Draggable from 'react-draggable';
import { INFO, ERROR, CHECK } from 'constants';
import { FILE_TYPE_THUMBNAIL } from '@/constants/fileType.js';
import React from 'react';
import { getResourceUrl } from '@/utils';

const ADD_DOWNLOAD_PARAMS_URL_GROUP = ['/api/Basic/Image/getImage', '/japi/cmm-basic/resource/content'];

const getIncludes = url => {
  let include = false;
  ADD_DOWNLOAD_PARAMS_URL_GROUP.forEach(value => {
    if (url.includes(value)) {
      include = true;
    }
  });
  return include;
};

export const downloadViewImage = props => {
  const { name, src } = props;
  const a = document.createElement('a');
  let url = src;
  // 判断是否是通过接口地址获取的 oss 图片，在接口上添加 download 参数，告诉后端需要下载，
  // 接口302 重定向的地址上加上response-content-disposition=attachment;filename=name 告知浏览器需要直接下载
  if (getIncludes(url)) {
    const originUrl = url.includes('download=1') ? url : `${url}&download=1&filename=${name}`;
    url = getResourceUrl({
      originUrl,
    });
  }
  a.href = url;
  a.download = name; // 获取文件名
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getFileInfo = item => {
  const fileType = getViewFileType(item);
  if (fileType === 'image') {
    const src = item.src || genImageURL(item);
    return {
      src,
      thumbnail: src,
      fileType,
    };
  } else {
    const src = item.src || getFileUrl(item);
    return {
      src,
      thumbnail: FILE_TYPE_THUMBNAIL[fileType],
      fileType,
    };
  }
};

export const formatFileItem = item => {
  const { src, thumbnail, fileType } = getFileInfo(item);
  return {
    ...item,
    src,
    fileType,
    original: item.original || src,
    thumbnail: item.thumbnail || thumbnail || src,
  };
};

export const getFiles = (props, fileList = []) => {
  let defaultIndex = 0;
  const { name, src: currentSrc } = props;

  const file_list = _.isObject(fileList) ? Object.values(fileList) : fileList;

  const files = file_list.map(formatFileItem);

  const index = files.findIndex(item => (name && item.name === name) || (currentSrc && item.src === currentSrc));
  if (index >= 0) {
    defaultIndex = index;
  } else {
    // 独立预览
    files.unshift(formatFileItem(props));
  }
  return {
    files,
    defaultIndex,
  };
};

export const defaultProps = {
  visible: true,
  modalProps: {
    title: <div style={{ textAlign: 'left' }}> 图片/文件预览 </div>,
    modalRender: modal => <Draggable handle=".gui-modal-header">{modal}</Draggable>,
    style: {
      width: '750px',
      padding: ' 0 24px 16px',
    },
    footer: null,
    closable: true,
    simple: true,
    autoFocus: true,
    icon: null,
  },
  galleryProps: {
    fitHeight: '450px',
    navPosition: 'thumbnail',
    thumbnailStyle: { width: 100, maxHeight: 100 },
    onKeySlide: (_e, direction, can) => {
      if (!can) {
        showInfo(INFO, direction === 'left' ? '已是第一张' : '已是最后一张');
      }
    },
  },
};

export const getGalleryConfig = props => {
  const { galleryProps } = props;
  const localGalleryProp = _.assign(defaultProps.galleryProps, galleryProps);

  return {
    ...localGalleryProp,
  };
};

export const getSpecialGalleryGroup = data => {
  // 空值不做处理
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return [];
  }
  const defaultInfo = data?.[0] || {};
  const { files } = getFiles(defaultInfo || {}, data);
  const newFiles = files?.map(file => {
    const newFile = {
      ...file,
    };
    if (!['image', 'pdf'].includes(file.fileType)) {
      delete newFile.fileType;
    }
    return newFile;
  });
  return newFiles;
};

export const getSpecialGalleryItem = item => {
  const newFile = formatFileItem(item);
  if (!['image', 'pdf'].includes(newFile.fileType)) {
    delete newFile.fileType;
  }

  return newFile;
};

export const customDownload = (info, fileList) => {
  const { name, src } = info;
  const findItem = fileList.find(item => (name && item.name === name) || (src && item.src === src));
  const downLoadFile = { src, name: findItem?.showName || name };
  downloadViewImage(downLoadFile);
};

/**
 * @description 将Base64编码的字符串转换为Blob对象
 * @param {string} base64Image Base64编码的字符串
 * @returns {Blob} 转换后的Blob对象，默认类型为'image/png', 因为对 png 格式支持较好
 */
export const base64ToBlob = base64Image => {
  // 移除 base64 前缀 "data:image/png;base64,"
  const base64Data = base64Image.split(',')[1];

  // 使用 TextEncoder 解码 base64 字符串
  const binaryString = window.atob(base64Data);
  const byteArray = Uint8Array.from(binaryString, char => char.charCodeAt(0));

  return new Blob([byteArray], { type: 'image/png' });
};

/**
 * @description 将 Blob 对象转换为 PNG 格式的 Blob 对象
 * @param blob 需要转换的 Blob 对象
 * @returns 返回一个新的 Promise 对象，resolve 时返回转换后的 PNG 格式 Blob 对象，reject 时返回错误信息
 * @throws 当 Blob 对象无法转换为图片时，会 reject 一个包含错误信息的 Promise 对象
 */
const convertBlobToPng = async blob => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(blob);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(pngBlob => {
        URL.revokeObjectURL(url);
        resolve(pngBlob); // 返回 PNG 格式的 blob
      }, 'image/png');
    };

    img.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('图片加载失败，无法转换为 PNG 格式'));
    };

    img.src = url;
  });
};

/**
 * @description 判断浏览器是否支持剪贴板操作
 * @returns {boolean}
 */
export const isNotSupportClipboard = () => {
  return !navigator.clipboard || typeof ClipboardItem === 'undefined';
};

/**
 * @description 将 Blob 对象复制到剪贴板
 * @param blob Blob 对象
 * @returns 无返回值
 * @throws 如果浏览器不支持剪贴板操作或 ClipboardItem 类型，则抛出异常
 */
export const copyBlobToClipboard = async blob => {
  try {
    if (isNotSupportClipboard()) {
      return showInfo(ERROR, '当前浏览器不支持图片复制到剪贴板，请手动复制图片');
    }

    // 检查 blob 类型是否为 PNG
    let pngBlob = blob;
    if (blob.type !== 'image/png') {
      // 将非 PNG 的 blob 转换为 PNG
      pngBlob = await convertBlobToPng(blob);
    }
    // eslint-disable-next-line no-undef
    const clipboardItem = new ClipboardItem({ 'image/png': pngBlob });
    await navigator.clipboard.write([clipboardItem]);
    showInfo(CHECK, '图片已成功复制');
  } catch (error) {
    showInfo(ERROR, '复制图片失败');
  }
};

export const fetchImage = async (imageUrl, fileName = '文件') => {
  try {
    let blob;
    if (imageUrl.startsWith('data:image/')) {
      blob = base64ToBlob(imageUrl);
    } else {
      let url = imageUrl;
      // 只有加上 download = 1 和 filename 参数，返回的oss 地址才是 https 的
      if (getIncludes(url)) {
        const originUrl = url.includes('download=1') ? url : `${url}&download=1&filename=${fileName}`;
        url = getResourceUrl({
          originUrl,
        });
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('无法获取图片');
      }
      blob = await response.blob();
    }
    return blob;
  } catch (error) {
    showInfo(ERROR, '无法获取图片或复制图片失败');
    return null;
  }
};

/**
 * @description 从指定URL获取图片并将其复制到剪贴板
 * @param imageUrl 图片的URL，可以是Base64编码的图片数据，也可以是图片的网络地址
 * @returns 无返回值
 * @throws 如果无法从指定URL获取图片或无法将图片复制到剪贴板，则抛出异常
 */
export const fetchImageAndCopy = async imageUrl => {
  try {
    const blob = await fetchImage(imageUrl);
    await copyBlobToClipboard(blob);
  } catch (error) {
    showInfo(ERROR, '无法获取图片或复制图片失败');
  }
};
