import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Gallery, Modal } from '@gui/web-react';
import { confirm, showInfo } from 'utils';
import {
  getFiles,
  getGalleryConfig,
  isNotSupportClipboard,
  fetchImage,
  copyBlobToClipboard,
  downloadViewImage,
} from './utils';
import { Load } from 'components';
import { INFO, ERROR } from 'constants';
import { IconDelete, IconCopy } from '@gui/web-react/icon';
import _ from 'lodash';

export default class EnhanceGallery extends PureComponent {
  static propTypes = {
    image: PropTypes.object,
    imgList: PropTypes.array,
    that: PropTypes.object,
    onSlide: PropTypes.func, // 切换图片的回调
    deleteSucceed: PropTypes.func, // 给外部提供删除成功后的回调，用于标识是否有删除图片
    onDeleteSucceed: PropTypes.func, // 删除成功后，关闭弹窗
    deleteImageName: PropTypes.string,
    withDelete: PropTypes.bool, // 是否需要删除
  };

  static defaultProps = {
    deleteImageName: '删除',
    withDelete: false,
  };

  constructor(props) {
    super(props);
    const {
      image,
      imgList,
      deleteUrl,
      customDeleteFun,
      deleteSucceed,
      onDeleteSucceed,
      deleteImageName,
      galleryProps,
      withDelete,
    } = props;
    this.copyLoading = false;
    const { onSlide } = galleryProps || {};
    const cloneImgList = _.cloneDeep(imgList);
    const self = this;
    // 存储图片的blob
    this.imageBlob = new Map();
    this.actions = [];
    const deletAction = {
      key: 'DeleteImage',
      content: <IconDelete />,
      name: deleteImageName,
      onClick: async () => {
        const index = self.gallery?.getCurrentIndex?.();
        const cur = cloneImgList[index];
        const len = cloneImgList.length;
        if (!(await confirm(INFO, '您确定要删除照片吗？', {}, '确认', null, null, null, { isModal: true })))
          return false;

        // TODO: 删除图片公共方法，直接提供接口
        // if (deleteUrl) {}
        if (customDeleteFun) {
          self.setState({
            loading: true,
          });
          const result = await customDeleteFun(cur, index);
          self.setState({
            loading: false,
          });
          if (!result) return;
        }

        // 触发已经成功删除了图片
        deleteSucceed?.();

        // 删除时，只有一张照片，删除成功后直接关闭预览弹窗
        if (len === 1) {
          Modal.destroyAll();
          onDeleteSucceed?.();
          return;
        }

        // 更新开始索引
        const startIndex = index === len - 1 ? len - 2 : index;
        // 删除后更新数据
        cloneImgList.splice(index, 1);
        self.updateImgList({
          imgList: cloneImgList,
          image: cloneImgList[startIndex],
          callback: () => {
            // 删除后执行切换图片回调
            onSlide?.(startIndex, cloneImgList);
          },
        });
      },
    };

    if (withDelete) {
      this.actions.push(deletAction);
    }
    // 如果浏览器支持剪切板，则添加复制图片按钮
    if (!isNotSupportClipboard()) {
      this.actions.push({
        key: 'copy',
        content: <IconCopy />,
        name: '复制图片',
        onClick: this.copyToClipboard,
      });
    }
    this.state = {
      image,
      imgList,
      loading: false,
    };
  }
  copyToClipboard = async () => {
    if (this.copyLoading) return;
    const curSlide = this.gallery?.getCurrentSlide?.();
    if (!curSlide?.src) {
      return showInfo(ERROR, '未获取到图片地址， 复制失败');
    }
    if (curSlide?.fileType !== 'image') {
      return showInfo(ERROR, '只支持复制图片');
    }
    this.copyLoading = true;
    if (this.imageBlob.has(curSlide)) {
      await copyBlobToClipboard(this.imageBlob.get(curSlide));
    } else {
      const getBlod = await this.setImageBlob(curSlide);
      await copyBlobToClipboard(getBlod);
    }
    this.copyLoading = false;
  };
  updateImgList({ imgList, image, callback }) {
    this.setState(
      {
        imgList,
        image,
      },
      () => {
        callback?.();
      },
    );
  }
  setImageBlob = async file => {
    if (this.imageBlob.has(file)) {
      return this.imageBlob.get(file);
    }
    const fileName = file?.showName || file?.name;
    const imgBlod = await fetchImage(file?.src || '', fileName);
    if (imgBlod) this.imageBlob.set(file, imgBlod);
    return imgBlod;
  };
  componentWillUnmount() {
    this.imageBlob.clear();
  }
  extendGalleryProps = files => {
    const { imgList } = this.state;
    let localGalleryProp = getGalleryConfig(this.props);
    localGalleryProp = {
      ...localGalleryProp,
      actions: [...(localGalleryProp.actions || []), ...this.actions],
    };
    localGalleryProp.customDownLoad = info => {
      const { name, src } = info;
      const findItem = files.find(item => (name && item.name === name) || (src && item.src === src));
      const downLoadFile = { src, name: findItem?.showName || name };
      downloadViewImage(downLoadFile);
    };

    const { onSlide } = localGalleryProp;
    localGalleryProp.onSlide = async index => {
      onSlide?.(index, imgList);
      if (this.imageBlob.has(files[index])) return;
      const fileName = files[index]?.showName || files[index]?.name;
      const blob = await fetchImage(files[index]?.src || '', fileName);
      if (blob) {
        this.imageBlob.set(files[index], blob);
      }
    };
    return localGalleryProp;
  };
  render() {
    const { image, imgList, loading } = this.state;
    const { files, defaultIndex } = getFiles(image, imgList);
    const curFile = files[defaultIndex];
    if (curFile) {
      this.setImageBlob(curFile);
    }
    const localGalleryProp = this.extendGalleryProps(files);
    return (
      <Load spinning={loading}>
        <Gallery {...localGalleryProp} ref={r => (this.gallery = r)} items={files} startIndex={defaultIndex} />
      </Load>
    );
  }
}
